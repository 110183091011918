var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theApp)?_c('a-row',{staticClass:"top-bar--fixed",class:_vm.$style.topbar,staticStyle:{"box-shadow":"0px 8px 28px rgba(100, 100, 100, 0.1)","background":"white"},attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{staticClass:"dF f1"},[(_vm.$bhapp === 'admin')?_c('router-link',{staticClass:"dF",attrs:{"to":"/select"}},[_c('img',{staticClass:"d-none d-md-block",style:(_vm.$env.env === 'prod'
					? { width: '130px', 'max-height': '40px' }
					: { 'max-height': '40px' }),attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/logo.svg')
				: require('@/assets/staging-logo.png')}}),_c('img',{staticClass:"d-block d-md-none",staticStyle:{"max-height":"40px"},attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/mobile-logo.png')
				: require('@/assets/staging-mobile-logo.png')}}),(_vm.instance && _vm.instance.name)?_c('div',{staticClass:"f1 fw-300 dF aC instance-name-box",staticStyle:{"margin-top":"5px"}},[_c('span',{staticClass:"text-lg text-black ml-4 block lXT"},[_vm._v(" "+_vm._s(_vm.instance.readableId ? _vm.instance.readableId + ' - ' : '')+" "+_vm._s(_vm.instance.name)+" ")])]):_vm._e()]):(_vm.$bhapp === 'superadmin')?_c('router-link',{staticClass:"dF",attrs:{"to":"/"}},[_c('img',{staticClass:"d-none d-md-block",style:(_vm.$env.env === 'prod'
					? { width: '130px', 'max-height': '40px' }
					: { 'max-height': '40px' }),attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/logo.svg')
				: require('@/assets/staging-logo.png')}}),_c('img',{staticClass:"d-block d-md-none",staticStyle:{"max-height":"40px"},attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/mobile-logo.png')
				: require('@/assets/staging-mobile-logo.png')}})]):_c('a',{staticClass:"dF aC",attrs:{"href":`https://admin.bildhive.${_vm.$tld}/apps`}},[_c('img',{staticClass:"d-none d-md-block",style:(_vm.$env.env === 'prod'
					? { width: '130px', 'max-height': '40px' }
					: { 'max-height': '40px' }),attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/logo.svg')
				: require('@/assets/staging-logo.png')}}),_c('img',{staticClass:"d-block d-md-none",staticStyle:{"max-height":"40px"},attrs:{"alt":"Bildhive","src":_vm.$env.env === 'prod'
				? require('@/assets/mobile-logo.png')
				: require('@/assets/staging-mobile-logo.png')}}),(_vm.instance && _vm.instance.name)?_c('div',{staticClass:"f1 fw-300 instance-name-box",staticStyle:{"margin-top":"5px"}},[_c('span',{staticClass:"text-lg text-black ml-4 block lXT"},[_vm._v(" "+_vm._s(_vm.instance.readableId ? _vm.instance.readableId + ' - ' : '')+" "+_vm._s(_vm.instance.name)+" ")])]):_vm._e()]),(_vm.instance?.id)?_c('div',{staticClass:"ml-3 dF",staticStyle:{"margin-top":"5px"}},[_c('ProjectSwitcher')],1):_vm._e()],1),_c('a-col',{staticStyle:{"display":"flex","align-items":"center"}},[[_c('div',{staticClass:"aC",staticStyle:{"display":"flex","justify-content":"flex-end"}},[(_vm.theApp && _vm.theApp.subdomain && _vm.instance && _vm.instance.name)?_c('a-tooltip',{attrs:{"overlayClassName":"change-tooltip-color","placement":"bottomRight","title":"Switch App","mouseEnterDelay":1}},[_c('a-avatar',{staticClass:"text-gray-6 bordered cursor-pointer instance-apps-trigger rotated mr-3",attrs:{"shape":"circle","size":"large","icon":"appstore"},on:{"click":function($event){_vm.drawerBoolean = true}}})],1):_vm._e(),_c('div',{staticClass:"badge-offer mr-3 d-none d-md-block",on:{"click":_vm.openHelpWidget}},[_c('a-icon',{attrs:{"type":"question-circle"}}),_vm._v("   Help ")],1),(_vm.theApp && _vm.theApp.subdomain && _vm.instance && _vm.instance.name)?_c('a-drawer',{attrs:{"title":_vm.instance.name,"placement":"right","width":320,"bodyStyle":{ overflow: 'auto', padding: '20px' },"visible":_vm.drawerBoolean},on:{"close":function($event){_vm.drawerBoolean = false}}},[_c('div',{staticClass:"text-center lT"},_vm._l((_vm.appsGrouped),function(cat,catI){return _c('div',{key:`appCat${cat.name}`},[(cat.name !== 'default')?_c('div',{staticClass:"text-left mb-3",class:catI === 0 ? '' : 'mt-5'},[_vm._v(" "+_vm._s(cat.name)+" ")]):_vm._e(),_c('div',{staticClass:"active-apps-grid"},_vm._l((cat.apps),function(app){return _c('div',{key:app.name,style:(app.subdomain === _vm.theApp.subdomain
									? 'cursor: not-allowed; filter: grayscale(1);'
									: '')},[_c('a',{style:(app.subdomain ===
										_vm.theApp.subdomain
										? {
											'pointer-events':
												'none',
										}
										: {}),attrs:{"href":`https://${app.subdomain}.bildhive.${_vm.$tld}`}},[_c('img',{staticStyle:{"width":"60px","height":"60px","object-fit":"contain"},attrs:{"alt":"","src":_vm.getAppIcon(app.media)}}),_c('small',{staticClass:"block mt-2 text-med-gray"},[_vm._v(_vm._s(app.name))])])])}),0)])}),0),_c('div',{staticClass:"mt-8"},[_c('hr'),_c('div',[_c('a-button',{staticClass:"w-full",attrs:{"href":`https://admin.bildhive.${_vm.$tld}/apps?marketplace=bh`,"type":"primary"}},[_vm._v("BACK TO WORKPLACE")])],1)])]):_vm._e()],1),_c('div',{staticClass:"mr-4 d-none d-xl-block dash-divider"})],_c('div',{staticClass:"mr-4 d-none d-md-block",staticStyle:{"display":"flex","justify-content":"flex-end"}},[(!_vm.$route.meta.redirectAuth && !_vm.$route.meta.redirectAuthTermsPrivacy)?_c('air-actions'):_vm._e()],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[(!_vm.$route.meta.redirectAuth && !_vm.$route.meta.redirectAuthTermsPrivacy)?_c('air-user-menu'):_vm._e()],1)],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }