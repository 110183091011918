import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
	topBar: {
		issuesHistory: 'Issues History',
		typeToSearch: 'Start typing to search...',
		actions: 'Actions',
		status: 'Status',
		profileMenu: {
			accountProfile: 'Account Profile',
			settingsPrivacy: 'Settings & Privacy',
			hello: 'Hello',
			billingHistory: 'Billing History',
			role: 'Role',
			email: 'Email',
			phone: 'Phone',
			editProfile: 'Edit Profile',
			logout: 'Logout',
			switchInstance: 'Switch Instance',
			instanceUsers: 'Instances & Users'
		},
	},
}

export default {
	locale: 'en-US',
	localeAntd,
	messages,
}
