import moment from 'moment'
const converter = require('number-to-words');

export let drawLot = {
	img: null,
	imgUrl: null,
	shape: null,
	shapeX: null,
	shapeY: null,
	shapeW: null,
	shapwH: null,
	shapes: null,
	width: null,
	height: null,
	canvas: null,
	ctx: null,
	siteplanImage: null,
	siteBlob: null,
	lotImage: null,
	lotBlob: null,
	initZoomCanvas(cv) {

		if (typeof cv === 'string') cv = document.querySelector(cv)
		else cv = document.createElement('canvas')

		return new Promise(async (resolve, reject) => {

			let ctx2 = cv.getContext('2d')
			let cW2 = this.shapeW
			let cH2 = this.shapeH


			let newW = this.shapeW + this.p2({ x: 4 })
			let newH = this.shapeH + this.p2({ y: 4 })

			let newX = this.shapeX - this.p2({ x: 2 });
			let newY = this.shapeY - this.p2({ y: 2 });

			cv.width = newW
			cv.height = newH

			if (newW > newH) newH = newW
			else newW = newH


			ctx2.drawImage(
				this.canvas,

				//old canvas clipping size
				newX, newY, newW, newH,

				//new canvas size
				0, 0, newW, newH

			)

			this.lotImage = cv.toDataURL('image/jpeg', 0.65);
			this.lotBuffer = await this.getArrayBuffer(cv)
			this.lotBlob = await this.getArrayBuffer(cv, true)

		})



	},
	reCoordinate() {

		let { x, y, width, height } = this.shape
		this.shapeX = this.p2({ x })
		this.shapeY = this.p2({ y })
		this.shapeW = this.p2({ x: width })
		this.shapeH = this.p2({ y: height })

	},
	sY(y) {
		return this.subP({ y })
	},
	sX(x) {
		return this.subP({ x })
	},
	subP({ x = false, y = false }) {
		if (x !== false) {
			let p = x / 100
			x = (p * this.shapeW) + this.shapeX
		}
		if (y !== false) {
			let p = y / 100
			y = (p * this.shapeH) + this.shapeY
		}

		let cond1 = x >= 0 && y === false
		let cond2 = x === false && y >= 0

		if (cond1) return x
		if (cond2) return y

		return { x, y }

	},
	p2({ x = false, y = false }) {
		if (x) {
			let p = x / 100
			return p * this.width
		}
		if (y) {
			let p = y / 100
			return p * this.height
		}
	},
	plotLines() {

		let ctx = this.ctx


		ctx.beginPath();
		ctx.lineWidth = "3";

		ctx.strokeStyle = 'yellow'

		let x0 = 0
		let y0 = 0

		this.shape.points.forEach(({ x, y }, cI) => {

			if (cI) return ctx.lineTo(this.sX(x), this.sY(y))
			x0 = x
			y0 = y
			ctx.moveTo(this.sX(x), this.sY(y))
		})
		// ctx.lineTo(this.sX(0), this.sY(0));
		ctx.lineTo(this.sX(x0), this.sY(y0))
		ctx.stroke();


	},
	highlightLot() {
		let { x, y, width, height } = this.shape

		x = this.p2({ x })
		y = this.p2({ y })
		width = this.p2({ x: width })
		height = this.p2({ y: height })

		this.ctx.beginPath()
		this.ctx.rect(x, y, width, height)
		this.ctx.stroke()
	},
	clipLot() {
		let { x, y, width, height } = this.shape

		this.ctx.strokeStyle = 'white'
		x = this.p2({ x })
		y = this.p2({ y })
		width = this.p2({ x: width })
		height = this.p2({ y: height })

		this.ctx.beginPath()
		this.ctx.rect(x - this.p2({ x: 1 }), y - this.p2({ y: 1 }), width + this.p2({ x: 2 }), height + this.p2({ y: 2 }))
		this.ctx.stroke()
		this.plotLines()
		this.ctx.clip()
		this.ctx.save()
	},
	async drawImage() {
		return new Promise((resolve, reject) => {
			this.img = new Image();
			this.img.crossOrigin = 'anonymous';
			this.img.onload = () => {
				const scaleFactorW = this.canvas.width / this.img.width;
				const scaleFactorH = this.canvas.height / this.img.height;

				const newImageWidth = this.canvas.width;

				const newImageHeight = this.img.height * scaleFactorH;

				this.ctx.drawImage(this.img, 0, 0, newImageWidth, newImageHeight);

				resolve();
			};
			this.img.onerror = () => reject();

			this.img.src = this.imgUrl;
		});
	},

	getArrayBuffer(cv, isBlob) {
		return new Promise((resolve, reject) => {
			cv.toBlob(blob => {
				if (isBlob) return resolve(blob)
				const reader = new FileReader();
				reader.addEventListener('loadend', () => {
					const arrayBuffer = reader.result;
					return resolve(arrayBuffer)
				});

				reader.readAsArrayBuffer(blob);

			}, 'image/jpeg', 1.0)
		})
	},
	async initShape(siteplan, shapeId) {

		let ctx = this.ctx
		let canvas = this.canvas

		this.imgUrl = siteplan.image
		this.width = siteplan.width
		this.height = siteplan.height
		this.shapes = siteplan.shapes
		this.shape = this.shapes.find(x => {
			return String(x.id) === String(shapeId)
		})
		canvas.width = this.width
		canvas.height = this.height

		if (!this.shape) console.error('caannot find shape, might be deleted')

		this.reCoordinate()
		await this.drawImage()
		this.plotLines()

		this.siteplanImage = this.canvas.toDataURL('image/jpeg', 0.65);
		this.siteBlob = await this.getArrayBuffer(this.canvas, true)
		this.siteplanBuffer = await this.getArrayBuffer(this.canvas)


		this.initZoomCanvas()
	},
	initCanvas(canvas = false) {
		if (typeof canvas === 'string') canvas = document.querySelector(canvas);
		else if (!canvas) canvas = document.createElement('canvas');

		this.ctx = canvas.getContext('2d');
		this.canvas = canvas


	}

}
export const numToWords = (num, options = {}) => {
	let { currency = true, allCaps } = options
	num = parseFloat(num)

	if (isNaN(num)) return ''

	let str = num + ''
	let decimal = str.split('.')
	let dollar = converter.numberToWords.toWords(decimal[0])

	if (currency) dollar += ' Dollars'

	let cents = decimal[1] ? converter.numberToWords.toWords(decimal[1]) : ''

	if (cents) {
		cents = ', and ' + cents
		if (currency) cents = cents + ' cents'
	}

	if (currency) dollar += ' Only'

	let capit = x => x[0].toUpperCase() + x.substr(1)
	let uppit = x => x.toUpperCase()
	let result = `${dollar}${cents}`
	result = result.split(' ').map(x => allCaps ? uppit(x) : capit(x)).join(' ')
	return result
}
import Holidays from 'date-holidays'
export const hd = new Holidays()
let initAlready = false

let regionConfig = {}
export let instanceConfig = {}

export const getInstanceConfig = (country, region) => {
	return instanceConfig
}

export const getRegionConfig = (country, region) => {
	let name = country + region
	let result = {
		warranty: '',
		currency: '',
	}

	if (name === 'CAON') {
		result = {
			warranty: 'tarion'
		}
	}

	return result
}

const regionLookup = {
	CA: [
		['AB', 'Alberta'],
		['BC', 'British Columbia'],
		['MB', 'Manitoba'],
		['NB', 'New Brunswick'],
		['NL', 'Newfoundland and Labrador'],
		['NS', 'Nova Scotia'],
		['NT', 'Northwest Territories'],
		['NU', 'Nunavut'],
		['ON', 'Ontario'],
		['PE', 'Prince Edward Island'],
		['QC', 'Quebec'],
		['SK', 'Saskatchewan'],
		['YT', 'Yukon'],
	],
	US: [
		['AL', 'Alabama'],
		['AK', 'Alaska'],
		['AZ', 'Arizona'],
		['AR', 'Arkansas'],
		['CA', 'California'],
		['LA', 'Los Angeles'],
		['CO', 'Colorado'],
		['CT', 'Connecticut'],
		['DE', 'Delaware'],
		['DC', 'District of Columbia'],
		['FL', 'Florida'],
		['GA', 'Georgia'],
		['HI', 'Hawaii'],
		['ID', 'Idaho'],
		['IL', 'Illinois'],
		['IN', 'Indiana'],
		['IA', 'Iowa'],
		['KS', 'Kansas'],
		['KY', 'Kentucky'],
		['LA', 'Louisiana'],
		['NO', 'New Orleans'],
		['ME', 'Maine'],
		['MD', 'Maryland'],
		['MA', 'Massachusetts'],
		['MI', 'Michigan'],
		['MN', 'Minnesota'],
		['MS', 'Mississippi'],
		['MO', 'Missouri'],
		['MT', 'Montana'],
		['NE', 'Nebraska'],
		['NV', 'Nevada'],
		['NH', 'New Hampshire'],
		['NJ', 'New Jersey'],
		['NM', 'New Mexico'],
		['NY', 'New York'],
		['NC', 'North Carolina'],
		['ND', 'North Dakota'],
		['OH', 'Ohio'],
		['OK', 'Oklahoma'],
		['OR', 'Oregon'],
		['PA', 'Pennsylvania'],
		['RI', 'Rhode Island'],
		['SC', 'South Carolina'],
		['SD', 'South Dakota'],
		['TN', 'Tennessee'],
		['TX', 'Texas'],
		['UT', 'Utah'],
		['VT', 'Vermont'],
		['VA', 'Virginia'],
		['WA', 'Washington'],
		['WV', 'West Virginia'],
		['WI', 'Wisconsin'],
		['WY', 'Wyoming'],
	]
}
const countryLookup = [
	['US', 'United States of America'],
	['US', 'UNITED STATES OF AMERICA'],
	['US', 'USA'],
	['CA', 'Canada'],
	['CA', 'Quebec'],
]

export const getCountry = (c) => {

	let found = countryLookup.find(x => x.includes(c))
	return found && found[0] || c
}
export const getRegion = (reg, country) => {

	if (!regionLookup[country]) return ''
	let found = regionLookup[country].find(x => {
		x = x.map(y => y.toLowerCase())
		return x.includes(reg.toLowerCase())
	})

	return found && found[0] || reg
}

export const initRegion = (arr = ['CA', 'ON']) => {
	hd.init(...arr)
	initAlready = true
}

const currRegionHolidays = (yy) => {
	return hd.getHolidays(yy).filter(h => h.type === 'public');
}

const isHoliday = (timestamp) => {

	let year = new Date(timestamp).getFullYear()
	let holidays = currRegionHolidays(year)

	let found = holidays.find(h => {
		let start = h.start.getTime()
		let end = h.end.getTime()
		return timestamp >= start && timestamp < end
	})
	return Boolean(found)
}
export const isWorkingDay = (timestamp) => {
	if (!initAlready) initRegion()
	let day = new Date(timestamp).getDay()
	return (day !== 6 && day !== 0 && !isHoliday(timestamp))
}

export const getFutureDate = (from, days) => {
	if (!initAlready) initRegion();
	if (from === 'N/A') return 'N/A';
	let now = moment(from)
	let result = now.add(days, 'days')
	let timestamp = result.unix() * 1000

	while (!isWorkingDay(timestamp)) {
		let transformNum = days >= 1 ? 1 : -1
		result = result.add(transformNum, 'days')
		timestamp = result.unix() * 1000
	}
	return result.unix() * 1000
}

window.getFDate = getFutureDate
export const initInstance = (instance) => {
	let { country = 'Canada', region = 'Ontario', productType } = instance
	if (!productType) productType = 'lowrise'
	if (!country) country = 'Canada'
	if (!region) region = 'Ontario'
	if (productType.includes('heighr')) productType = 'highrise'

	let c = getCountry(country)
	let r = getRegion(region, c)

	let args = [c, r]

	regionConfig = getRegionConfig(c, r)
	instanceConfig = {
		productType,
		countryCode: c,
		regionCode: r,
	}

	initRegion(args)
}

const calculateFreeHold = closingDateTimeStamp => {

	let closingDate1 = getFutureDate(closingDateTimeStamp, 0)
	let secondTentativeDate = getFutureDate(closingDateTimeStamp, 120); // set firm closing date
	let firmClosingDate = getFutureDate(secondTentativeDate, 120); // set outside closing date
	let outsideClosingDate = getFutureDate(secondTentativeDate, 365);
	let terminationDate = getFutureDate(outsideClosingDate, 30); // Notice to set Firm Closing Date - 7 months ?
	let noticeFirmDate = getFutureDate(secondTentativeDate, -90); // Notice to set Second Tentative Closing Date - 90 days before 7 months?
	let noticeSecondDate = getFutureDate(closingDateTimeStamp, -90); // set First tentative date

	return {
		alerts: [{
			date: '',
			msg: '',
		}],
		result: {
			closingDate1,
			secondTentativeDate,
			firmClosingDate,
			outsideClosingDate,
			terminationDate,
			noticeFirmDate,
			noticeSecondDate
		}
	}
}
const calculateFreeHoldFirm = closingDateTimeStamp => {
	let firmClosingDate = getFutureDate(closingDateTimeStamp, 0)
	let outsideClosingDate = getFutureDate(closingDateTimeStamp, 365);
	let terminationDate = getFutureDate(outsideClosingDate, 30);
	return {
		result: {
			firmClosingDate,
			outsideClosingDate,
			terminationDate
		}
	}
}
const calculateCondoFirm = (closingDateTimeStamp, outsideClosing) => {
	let firmClosingDate = getFutureDate(closingDateTimeStamp, 0)
	let outsideClosingDate = getFutureDate(outsideClosing, 0);
	let terminationDate = getFutureDate(outsideClosing, 30);
	return {
		result: {
			firmClosingDate,
			outsideClosingDate,
			terminationDate
		}
	}
}
const calculateCondoTentative = (closingDateTimeStamp, outsideClosing) => {
	let closingDate1 = getFutureDate(closingDateTimeStamp, 0);
	let firmClosingDate = getFutureDate(closingDateTimeStamp, -90);
	let outsideClosingDate = getFutureDate(outsideClosing, 0);
	let noticeSecondDate = getFutureDate(closingDateTimeStamp, -90);
	let terminationDate = getFutureDate(outsideClosing, 30);
	return {
		result: {
			closingDate1,
			firmClosingDate,
			outsideClosingDate,
			noticeSecondDate,
			terminationDate
		}
	}
}

export const transformTransaction = transaction => {

	let config = regionConfig
	let { product } = transaction
	if (config.warranty) {
		if (config.warranty === 'tarion') {
			if (instanceConfig.productType === 'lowrise') {
				if (transaction.offer.closingType === 'firm') {
					let dates = calculateFreeHoldFirm(transaction.offer.closingDate)
					transaction.other[config.warranty] = dates.result
					transaction.alerts = dates.alerts
				} else if (transaction.offer.closingType === 'tentative') {
					let dates = calculateFreeHold(transaction.offer.closingDate)
					transaction.other[config.warranty] = dates.result
					transaction.alerts = dates.alerts
				}
			} else {
				if (transaction.offer.closingType === 'firm') {
					let dates = calculateCondoFirm(transaction.offer.closingDate, transaction.offer.outsideClosing)
					transaction.other[config.warranty] = dates.result
					transaction.alerts = dates.alerts
				} else if (transaction.offer.closingType === 'tentative') {
					let dates = calculateCondoTentative(transaction.offer.closingDate, transaction.offer.outsideClosing)
					transaction.other[config.warranty] = dates.result
					transaction.alerts = dates.alerts
				}
			}
		}
	}

}
