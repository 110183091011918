import Vue from 'vue'
import axios from 'axios'
import { initInstance, instanceConfig } from 'bh-mod/helper'
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import jwt_decode from "jwt-decode";
import moment from "moment";

export const diffDays = (date1, date2) => {
	if (!date2) {
		date2 = date1
		date1 = new Date()
	}
	date1 = new Date(date1);
	date2 = new Date(date2);
	let theDate1 = date1.getDate()
	let theDate2 = date2.getDate()
	const differenceInTime = date2.getTime() - date1.getTime();
	let diffInDays = Math.floor(differenceInTime / (1000 * 3600 * 24))
	if (diffInDays === 0) {
		return theDate1 === theDate2 ? 0 : 1
	}
	return diffInDays
}

export const getCookie = (cname) => {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length)
		}
	}
	return "";
}

export const setCookie = (cname, cvalue, exdays) => {
	var d = new Date()
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
	var expires = "expires=" + d.toUTCString();

	let cook = cname + "=" + cvalue

	if (exdays && exdays.length) {
		cook += `; ${expires}`
	}

	if (!location.href.includes('localhost')) cook += `; domain=bildhive.${tld}`

	document.cookie = cook + '; path=/'
}

export const checkCookie = (cookie) => {
	var cookie = getCookie(cookie);
	return user != ''
}

export const tld = location.host.includes('bildhive.dev') || window.location.host.includes('localhost') ? 'dev' : 'com'
Vue.prototype.$tld = tld
export const markerIcon = 'https://bildhive.nyc3.digitaloceanspaces.com/7ce705321e9b41149e994384000ae2db.png'
export const DBURL = location.href.includes('//localhost:') && getCookie('__DBURL') ? getCookie('__DBURL') : `https://api.bildhive.${tld}`
export const _FP = { id: '' }

const urlParams = new URLSearchParams(location.search)

export const $auth = axios.create({
	baseURL: DBURL,
})

export const $api = axios.create({
	baseURL: DBURL,
})
const decjwt = jwt => {
	if (!jwt) return {}
	try {
		return jwt_decode(jwt);
	} catch (er) {
		return {}
	}
}
const isExpired = jwt => {
	if (!jwt) return true
	let decoded = {}
	try {
		decoded = decjwt(jwt)
		if (decoded.exp) return decoded.exp < (Date.now() / 1000)
		throw new Error('no exp')
	}
	catch (err) { return true }
};

const generateFingerPrint = async () => {
	if (getCookie('__bhfp')) {
		_FP.id = getCookie('__bhfp')
	} else {
		const fpPromise = FingerprintJS.load()
		const fp = await fpPromise
		const fingp = await fp.get();
		_FP.id = fingp.visitorId
		setCookie('__bhfp', _FP.id, '60')
	}
	$api.defaults.headers["x-fp"] = _FP.id
	$auth.defaults.headers["x-fp"] = _FP.id
}

(async () => {
	await generateFingerPrint();
})();

import * as Sentry from "@sentry/vue";
export const initSentry = (router) => {
	if (sessionStorage.dev) return
	let environment = 'staging'
	if (location.host.includes('bildhive.com')) environment = 'production'
	if (location.host.includes('localhost')) environment = 'development'
	Sentry.init({
		Vue,
		environment,
		dsn: "https://fd8220ede0b94a388e5281ff6b4e6ae4@o1092357.ingest.sentry.io/6110759",
		debug: true,
		maxBreadcrumbs: 50,
		integrations: [Sentry.browserTracingIntegration(router)],
		tracePropagationTargets: ["bildhive.dev", "bildhive.com", /^\//],
		tracesSampleRate: 1.0,
		enabled: ['staging', 'production'].includes(environment),
		ignoreErrors: [
			/Redirected when going from ".*" to ".*" via a navigation guard\./,
			/Navigation cancelled from ".*" to ".*" with a new navigation\./,
			/Navigating to current location .* is not allowed/,
			'Avoided redundant navigation to current location',
		]
	})

	let [subdomain, bild, tld] = location.host.split('.')[0]

	if (subdomain) {
		Sentry.setTag("Subdomain", subdomain);
	}
	if (tld) {
		Sentry.setTag('Environment', tld === 'dev' ? 'Staging' : 'Production')
	}
}

window.$log = (...args) => {
	return !location.host.endsWith('.com') && console.log(...args)
}
const theHost = window.location.hostname

Vue.prototype.$env = {
	env: theHost === 'localhost' ? 'local' : theHost.includes('bildhive.com') ? 'prod' : 'staging',
}

Vue.prototype.$isDEV = process.env.NODE_ENV !== 'production' || location.host.includes('localhost')
Vue.prototype.$isStaging = location.host.includes('.dev')
Vue.prototype.$noimg = 'https://bildhive.nyc3.digitaloceanspaces.com/assets/noimageavailable.png'


/**
 * Global req rule function
 * @param {string} msg message you want to display
 * @param {boolean} required field is required or not
 * @returns Object containing required with message
 */
Vue.prototype.$req = (msg, required = true) => ({ message: msg, required: required })

/**
 *
 * @param {Object} err Error Object
 * @param {string} fallBack Fallback error message if no message parsed from error object
 */
Vue.prototype.$toastError = (err, fallBack = false) => {
	if (err && err.response && err.response.status !== 400) {
		Vue.prototype.$message.error(parseError(err, fallBack));
	}
}

/**
 * Formats a date object to a string based on the format you want.
 *
 * @param {Object} date date object
 * @param {string} format date format you want to use. default is 'll'
 * @returns formatted date
 */
Vue.prototype.$formatDate = (date, format = 'll') => {
	if (!date) return '';
	return moment(date).format(format);
}

/**
 * Formats a number based on locale and custom formatting options.
 *
 * @param {number} num - The number to be formatted. If falsy (e.g., null, undefined), the original value will be returned.
 * @param {boolean} [useGrouping=true] - Whether to use grouping separators (commas, dots, etc.). Default is true.
 * @param {number} [minimumFractionDigits=0] - The minimum number of decimal places to display. Default is 0.
 * @param {number} [maximumFractionDigits=2] - The maximum number of decimal places to display. Default is 2.
 *
 * @returns {string|number} - The formatted number as a string, or the original number if it is falsy. If an error occurs, the number will be formatted using 'en-US' as a fallback locale.
 */
Vue.prototype.$formatNumber = (num, useGrouping = true, minimumFractionDigits = 0, maximumFractionDigits = 2) => {
	if (!num) return num;

	// Define a set of countries to ignore special locale formatting
	const ignoreUS = {
		us: true,
		US: true,
		USA: true,
		CA: true,
	};

	const defaultLocale = 'en-US'; // Default fallback locale
	let countryCode = instanceConfig && instanceConfig.countryCode || 'US'; // Fallback to 'US' if instanceConfig or countryCode is undefined
	let regionCode = instanceConfig && instanceConfig.regionCode || ''; // Handle regionCode gracefully if undefined

	// Format locale properly (like "en-US")
	let locale = `${countryCode}-${regionCode}`.toUpperCase();

	// If the country should be ignored (e.g., 'US', 'CA'), use default locale
	if (ignoreUS[countryCode]) {
		locale = defaultLocale;
	}

	// Number formatting options
	const options = {
		useGrouping,
		minimumFractionDigits,
		maximumFractionDigits
	};

	try {
		// Try formatting the number with the determined locale and options
		return num.toLocaleString(locale, options);
	} catch (e) {
		console.error(`Error formatting number with locale "${locale}":`, e);
		// Fallback to default 'en-US' formatting if an error occurs
		return num.toLocaleString(defaultLocale, options);
	}
}

/**
 * Global default function to check if a search input is there in the list options
 * @param {string} input Search input given by user
 * @param {Object} option select list option to search against
 * @returns { boolean } if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded.
 */
Vue.prototype.$filterOption = (input, option) => {
	return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
}

/**
 * Global function to format file size in human readable format
 * @param {number} kb file size in kilobytes
 * @param {number} decimals number of decimal places
 * @returns
 */
export const formatFileSize = (kb, decimals = 2) => {
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['KB', 'MB', 'GB', 'TB'];
	const i = Math.floor(Math.log(kb) / Math.log(k));
	return parseFloat((kb / Math.pow(k, i)).toFixed(dm)) + ' ' + (sizes[i] || 'Bytes');
}

/**
 * Global function to debounce a function
 * @param {function} func function to be debounced
 * @param {number} wait time to wait before calling the function in milliseconds
 * @returns {function} debounced function
 */
export const debounceFunc = (func, wait) => {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			func.apply(context, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	}
}

export let $util = {}
export let $sssss = {}
const $_ = {
	env: process.env.NODE_ENV
}
export const findById = (arr, id) => arr.find(x => x.id === id)

export const arrToObj = (arr, key = 'id') => {
	let obj = {}
	if (!arr || !arr.length) return obj
	if (arr[0].updatedAt) {
		arr = arr.sort(({ updatedAt }, { updatedAt: updatedAt2 }) => updatedAt < updatedAt2 ? 1 : -1)
	}
	arr.forEach(x => {
		if (x[key]) obj[x[key]] = x
	})
	return obj
}
export const checkStale = () => {
	let isStale = false
	if ($_.app && $_.store && $_.instance) {
		let cookieInstance = getCookie('instance')
		if (cookieInstance !== $_.instance) {
			console.error('STALE DATA. cookie instance is => ', cookieInstance)
			isStale = true
		}
	}
	return { isStale, onContinue: () => setCookie('instance', $_.instance, '15') }

}

export const setProp = (obj, props, value) => {
	const prop = props.shift()
	if (!obj[prop]) {
		Vue.set(obj, prop, {})
	}
	if (!props.length) {
		if (value && typeof value === 'object' && !Array.isArray(value)) {
			obj[prop] = { ...obj[prop], ...value }
		} else {
			obj[prop] = value
		}
		return
	}
	setProp(obj[prop], props, value)
}


export const deleteProp = (obj, props) => {
	const prop = props.shift()
	if (!obj[prop]) {
		return
	}
	if (!props.length) {
		Vue.delete(obj, prop)
		return
	}
	deleteProp(obj[prop], props)
}

export const scrollingContainerShadow = (e) => {
	let top = e.target.scrollTop
	if (e.target.parentElement) {
		if (top > 11) e.target.parentElement.classList.add('scrolling-shadow')
		else e.target.parentElement.classList.remove('scrolling-shadow')
	}
}

export const ruleReq = (msg = '', required = true) => {
	return { required: required, message: msg }
}
export const ruleValidEmailReq = (rule, value, callback) => {
	if (!value) return callback(new Error('Cannot be empty!'))
	if (!validateEmail(value)) return callback(new Error('Not a valid Email!'))
	return
}

export const ruleValidInt = (rule, value, callback) => {
	if (!value || !parseInt(value) || parseInt(value) < 0) {
		return callback(new Error());
	}
}

export const getAppIcon = (media) => {
	return media.icons.svg || media.icons.png
}

export const downloadURL = (url, filename) => {
	if (!filename) filename = url.split('/').pop().split('?')[0].split('#')[0]
	return new Promise((resolve, reject) => {
		axios.get(url, { responseType: 'blob' }).then(({ data }) => {
			let a = document.createElement('a')
			a.href = window.URL.createObjectURL(data)
			a.download = filename
			a.click()
			setTimeout(() => {
				resolve()
			}, 100);
		}).catch(err => {
			reject(err)
		})
	})
}

export const downloadCSV = (url, filename, subdomain = '') => {
	let instance = getCookie('instance');
	url = url.replace(':instance', instance);
	if (url.startsWith('/')) url = url.slice(1);

	return fetch(`https://hook.bildhive.${tld}/${url}`, {
		headers: {
			Authorization: `Bearer ${getCookie("__bhjt")}`,
			"Content-Type": "text/csv",
			"x-fp": getCookie("__bhfp"),
			"x-instance": instance,
			"x-subdomain": subdomain
		},
		responseType: "blob"
	}).then(response => response.blob()).then(blob => {
		const a = document.createElement("a");
		a.href = URL.createObjectURL(blob);
		a.download = `${filename || Date.now()}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}).catch(err => Promise.reject(err));
}

export const refreshToken = async () => {
	return new Promise(async (resolve) => {
		let jwt = getCookie('__bhjt')
		let refreshT = getCookie('__bhr')

		if (!isExpired(jwt)) return resolve({ jwt, refreshT })

		if (!isExpired(refreshT)) {
			return $auth.post('/auth/refreshToken', {
				"token": refreshT,
				"renew": true
			}).then(({ data: tokens }) => {
				if (tokens.jwt && tokens.refresh) {
					setCookie('__bhjt', tokens.jwt)
					setCookie('__bhr', tokens.refresh, '15')
					return resolve({ jwt: tokens.jwt, refreshT: tokens.refresh })
				}
				resolve({ jwt: '', refreshT: '' })
				throw new Error('no token')

			}).catch(err => {
				setCookie('__bhjt', '')
				setCookie('__bhr', '')
				return resolve({ jwt: '', refreshT: '' })
			})
		}
		return resolve({ jwt: '', refreshT: '' })
	})
}

$api.interceptors.response.use(
	response => {
		return response
	},
	error => {
		console.error('Error =>', error)
		const { response } = error;
		if (response) {
			const { status, data } = response;
			if (status === 400) {
				if (data && data.id === 'Auth.password.expired' && !window.location.href.includes('settings/accounts?password-reset')) {
					window.location.replace(`https://admin.bildhive.${tld}/settings/accounts?password-reset`);
				} else if (data && data.message) {
					Vue.prototype.$message.error(data.message);
				}
			} else if (status === 401 && data && data.errorCode === 'INVALID_DEVICE') {
				Vue.prototype.$message.error(data.message || 'Device not authorized');
				clearAuth($_.store);
				if (!location.href.includes('localhost')) {
					window.location.replace(`https://admin.bildhive.${tld}/system/login`);
				}
			}
		}
		return Promise.reject(error)
	}
)

export const VueAppMiddleware = (to, store, appName, query = '') => {
	return new Promise(async (resolve) => {
		if (urlParams.get('__bhr')) {
			setCookie('__bhr', urlParams.get('__bhr'), '15')
		}
		if (urlParams.get('__bhjt')) {
			setCookie('__bhjt', urlParams.get('__bhjt'))
		}
		if (urlParams.get('__bhi')) {
			setCookie('instance', urlParams.get('__bhi'), '15')
		}
		if (urlParams.get('__bhfp') && location.href.includes('localhost')) {
			setCookie('__bhfp', urlParams.get('__bhfp'), '60')
		}

		$_.store = store
		let goComeBack = (reason = '') => {

			let instance = getCookie('instance') || ''
			setCookie('toI', instance)
			if (!location.href.includes('localhost')) {
				return location.href = `https://admin.bildhive.${tld}/system/login`
			}
		}

		let { jwt } = await refreshToken()

		if (!jwt) {
			return goComeBack('no token')
		}
		let instance = getCookie('instance')

		$_.instance = instance
		if (!instance) return goComeBack(' NO INSTANCE')

		// Generate a new fingerprint if it doesn't exist
		await generateFingerPrint();
		$api.defaults.headers["x-instance"] = instance

		$api.defaults.instance = instance
		$api.interceptors.request.use(
			async request => {
				await refreshToken()
				request.headers["Authorization"] = `Bearer ${getCookie('__bhjt')}`
				request.url = request.url.replace(':instance', instance)
				return request
			},

			error => {
				return Promise.reject(error)
			}
		)

		$api.get(`/hives/${instance}/${appName}${query}`).then(({ data: { hive, role = {}, instance, user, data = false, settings = {} } }) => {
			console.log('App name:', appName)
			$api.defaults.headers["x-subdomain"] = appName

			user.settings = settings.user;
			saveAuth({ jwt }, user, store)
			store.commit('SET_INSTANCE', instance)
			initInstance(instance)

			store.dispatch('SET_APPDATA', data)
			store.commit('SET_SETTINGS', settings)
			store.commit('SET_APP', hive)
			Sentry.setUser({ email: user.email, id: user.id, firstName: user.firstName || 'n/a', lastName: user.lastName || 'n/a' })
			Sentry.setTag('User', user.email)
			Sentry.setTag('Instance', instance.name)
			Sentry.getCurrentScope(scope => scope.setTransactionName(`I:${instance.name} - A:${hive.name}`));
			Sentry.setTag('InstanceID', instance.id)
			Sentry.setTag('MemberType', user.email.includes('redngen.com') || user.email.includes('bildhive.com') || user.email.includes('ngenagency.com') ? 'Internal' : 'External')

			$_.app = hive.name
			if (role.permissions) {
				let level = 0
				if (role.permissions.admin) level = 50
				Vue.prototype.$p = role.permissions[appName] || level
			}
			if (appName == 'reports') {
				store.commit('SET_$P', Vue.prototype.$p)
			}

			resolve({})
		}).catch(err => {
			resolve({ error: 'server', response: err, parsed: parseError(err) })
		})
	})
}


export const isLogged = (jwt, store) => {
	return new Promise(async (resolve) => {
		// Generate a new fingerprint if it doesn't exist
		if (!_FP.id) {
			await generateFingerPrint();
		}
		if (!jwt) resolve(false)
		$api.defaults.headers["Authorization"] = `Bearer ${jwt}`
		$api.get('/users/me').then(({ data }) => {
			saveAuth({ jwt }, data, store)
			resolve({ success: true })
			if (data.mustResetPassword) {
				if (!window.location.href.includes('settings/accounts?password-reset')) {
					window.location.replace(`https://admin.bildhive.${tld}/settings/accounts?password-reset`);
				}
			}
			resolve(true)
		}).catch(err => {
			setCookie('__bhjt', '')
			setCookie('__bhr', '')
			resolve(false)
		})
	})
}

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}
export const saveAuth = ({ jwt, refresh }, user, store) => {
	Vue.prototype.$userType = user && user.isAgent ? 'agent' : 'user'
	if (jwt) {
		setCookie('__bhjt', jwt)
		$api.defaults.headers["Authorization"] = `Bearer ${jwt}`
		if (refresh) {
			setCookie('__bhr', refresh, '15')
		}
		if (user && user.id) {
			store.commit('UPDATE_USER', user)
		}
	} else {
		setCookie('__bhjt', '')
		setCookie('__bhr', '')
		store.commit('UPDATE_USER', { user: {} })
		$api.defaults.headers["Authorization"] = ``
	}
}

export const clearAuth = (store) => {
	Sentry.getCurrentScope(scope => scope.setUser(null));
	setCookie('__bhjt', '')
	setCookie('__bhr', '')
	store.commit('UPDATE_USER', { user: {} })
	$api.defaults.headers["Authorization"] = ``
}
export const getInitial = (str = 'Bildhive', second = '') => {
	if (!str) return;
	let names = [str]
	if (str.trim().includes(' ')) names = str.split(' ')
	else if (second) names.push(second)
	let initial = ''
	names.forEach((x, xI) => {
		if (xI < 2) initial += x[0]
	})
	return initial.toUpperCase()
}
export const getRoleName = (role = 'No Role Access') => {
	if (role === 'bh_owner') role = 'Owner'
	if (role === 'bh_admin') role = 'Admin'
	if (role === 'bh_agent') role = 'VIP Realtor'
	if (role === 'bh_agent_manager') role = 'VIP Broker Manager'
	return role
}

export const validateEmail = (email) => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export const formatDate = (date) => {
	if (!date) return;
	if (typeof date === 'string' || typeof date === 'number') date = String(new Date(date)).split(' ')
	return `${date[1]}/${date[2]}/${date[3]}`
}

export const parseError = (error, fallBack = false) => {
	if (error.response && error.response.data) error = error.response.data
	let idErrors = {
		"Auth.form.error.email.taken": 'Sorry! That Email has been taken already',
		"Auth.form.error.email.invalid": "Sorry! That email is invalid.Please try again"
	}
	if (!error.message) {
		if (error.id) return idErrors[error.id] || resultObj.msg
	}
	let getMsg = (msg) => {
		if (!Array.isArray(msg)) return { type: false }
		let result = null
		msg.forEach(x => {
			if (x.message && Array.isArray(x.message)) result = { type: 'array', msg: x.message }
			else if (x.messages && Array.isArray(x.messages)) result = { type: 'array', msg: x.messages }
			else if (typeof x.message === 'string') result = { type: 'string', msg: x.message }
			else if (x.id) result = { type: 'string', msg: idErrors[x.id] || x.id }
			else result = { type: false }
		})
		return result
	}

	let msgArray = error.message
	if (!msgArray && error.statusText) return error.statusText
	if (!msgArray && error.error) return error.error
	if (typeof msgArray === 'string') return msgArray
	let resultObj = getMsg(msgArray)

	while (resultObj.type === 'array') {
		resultObj = getMsg(resultObj.msg)
	}

	if (resultObj.type === 'string') return resultObj.msg
	else return fallBack || 'Oops. Something went wrong.'
}


export const formatNumber = (num) => {
	if (!num) return num;
	let ignoreUS = {
		us: true,
		US: true,
		USA: true,
		CA: true,
	}
	let c = instanceConfig.countryCode
	let r = instanceConfig.regionCode
	let format = `us-EN`
	if (!ignoreUS[c]) format = c
	try {
		return num.toLocaleString(format);
	} catch (e) {
		return num.toLocaleString('us-EN');
	}
}
export const getQueryVariable = (variable) => {
	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
}
export const checkAlphaNum = (str) => {
	return str.match(/^[-\w\s]+$/)
}

export const log = (e, f = false, color = 'blue') => {
	if (f) console.log("%c Log =>", "color:" + color, f)
}
