var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ air__layout__grayBackground: _vm.settings.isGrayBackground }},[_c('a-layout',{class:{
		air__layout__contentNoMaxWidth: _vm.settings.isContentNoMaxWidth,
		air__layout__appMaxWidth: _vm.settings.isAppMaxWidth,
		air__layout__grayBackground: _vm.settings.isGrayBackground,
		air__layout__squaredBorders: _vm.settings.isSquaredBorders,
		air__layout__cardsShadow: _vm.settings.isCardShadow,
		air__layout__borderless: _vm.settings.isBorderless,
	}},[_c('air-topbar'),_c('a-layout',{staticClass:"dF"},[(_vm.$route.meta.sidebar)?_c('air-menu-left'):_vm._e(),_c('a-layout',{staticClass:"f1 content-side--layout"},[_c('a-layout-header',{staticClass:"air__layout__header",class:{
					air__layout__fixedHeader: _vm.settings.isTopbarFixed,
					air__layout__headerGray: _vm.settings.isGrayTopbar,
				}}),_c('a-layout-content',{staticClass:"dF relative"},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }