import localeAntd from 'ant-design-vue/lib/locale-provider/zh_CN'

const messages = {
	topBar: {
		issuesHistory: '发布历史',
		typeToSearch: '搜索...',
		actions: '动作',
		status: '状态',
		profileMenu: {
			hello: '你好',
			billingPlan: '结算计划',
			role: '角色',
			email: '电子邮件',
			phone: '电话',
			editProfile: '编辑个人资料',
			logout: '登出',
		},
	},
}

export default {
	locale: 'zh-CH',
	localeAntd,
	messages,
}
